// src/store/streamerExtensionsReducer.ts

import { Reducer } from "redux";
import { IExtension } from "../../components/extensions/IExtensions";

// Action types
export const SET_EXTENSIONS = 'SET_EXTENSIONS';
export const ADD_EXTENSION = 'ADD_EXTENSION';
export const UPDATE_EXTENSION = 'UPDATE_EXTENSION';
export const REMOVE_EXTENSION = 'REMOVE_EXTENSION';

// Action creators
export const setStreamerExtensions = (extensions: IExtension[]) => ({
    type: SET_EXTENSIONS,
    payload: extensions,
});

export const addStreamerExtension = (extension: IExtension) => ({
    type: ADD_EXTENSION,
    payload: extension,
});

export const updateStreamerExtension = (extension: IExtension) => ({
    type: UPDATE_EXTENSION,
    payload: extension,
});

export const removeStreamerExtension = (clientId: string) => ({
    type: REMOVE_EXTENSION,
    payload: clientId,
});

// Reducer
type ExtensionsState = IExtension[];

const initialState: ExtensionsState = [];

const streamerExtensionsReducer: Reducer<ExtensionsState> = (state = initialState, action) => {
    switch (action.type) {
        case SET_EXTENSIONS:
            return action.payload;
        case ADD_EXTENSION:
            return [...state, action.payload];
        case UPDATE_EXTENSION:
            return state.map(ext => ext.clientId === action.payload.clientId ? { ...ext, ...action.payload } : ext);
        case REMOVE_EXTENSION:
            return state.filter(ext => ext.clientId !== action.payload);
        default:
            return state;
    }
};

export default streamerExtensionsReducer;
