import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import EmoteProvider from "../providers/EmoteProvider"
import { RootState } from "../store"
import { StreamerProfileProvider } from "./contexts/StreamerProfileContext"
import { useToken } from "./contexts/TokenContext"
import { UserOnStreamerProvider } from "./contexts/UserOnStreamerProfileContext"
import WebSocketProvider from "./contexts/WebSocketContext"
import LoadingSpinner from "./misc/loading/LoadingSpinner"
import NewLanding from "./newlanding/NewLanding"
import PrivacyPolicy from "./routes/privacypolicy/PrivacyPolicy"
import TermsOfService from "./routes/termsofservice/TermsOfService"
import TokenSetter from "./services/TokenSetter"
import SettingsOverlay from "./settings/SettingsOverlay"
import UserPage from "./username/UsernamePage"

const Content: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const { token } = useToken()

  const isLoading = useSelector((state: RootState) => state.loading)
  const isSettingsOpen = useSelector(
    (state: RootState) => state.settings.isSettingsOpen
  )

  useEffect(() => {
    // Check for token directly from local storage

    // If there is a token and we're on the root path, navigate to /cowsep
    if (token && location.pathname === "/") {
      navigate("/cowsep")
    }
  }, [navigate, location.pathname, token])

  useEffect(() => {
    // Define the event listener
    const handleMessage = (event: any) => {
      // Implement origin check and message handling logic
      if (event.data && event.data.type === 'pong') {
        return; // Skip logging if the message type is 'pong'
      }

      if (event.data.type === "console_log") {
        console.log("Our Extension says:", event.data.message)
      }
    }

    // Add event listener
    window.addEventListener("message", handleMessage)

    // Cleanup listener on component unmount
    return () => window.removeEventListener("message", handleMessage)
  }, [])

  return (
    <TokenSetter>
      <Routes>
        <Route path="/" element={<NewLanding />} />

        <Route
          path="/:username"
          element={
            <StreamerProfileProvider>
              <UserOnStreamerProvider>
                <WebSocketProvider>
                  <EmoteProvider>
                    <UserPage />

                    {isSettingsOpen && <SettingsOverlay />}
                    {isLoading && <LoadingSpinner />}
                  </EmoteProvider>
                </WebSocketProvider>
              </UserOnStreamerProvider>
            </StreamerProfileProvider>
          }
        />

        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
      </Routes>
    </TokenSetter>
  )
}

export default Content
