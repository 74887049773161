import axios from "axios"

export async function getExtensionClientSecret(
  clientId: string,
  token: string | null
) {
  if (!token) throw new Error("Unauthorized.")

  const response = await axios.post(
    `${process.env.REACT_APP_SERVER_URL}/developer/getsecret`,
    {
      clientId, // Send clientId in the body of the POST request
    },
    { headers: { Authorization: `Bearer ${token}` } }
  )

  return response.data.clientSecret
}

export async function createExtensionClientSecret(
  clientId: string,
  token: string | null
) {
  if (!token) throw new Error("Unauthorized.")

  const response = await axios.post(
    `${process.env.REACT_APP_SERVER_URL}/developer/newsecret`,
    { clientId },
    { headers: { Authorization: `Bearer ${token}` } }
  )

  return response.data.newClientSecret
}
