import { FaCog } from "react-icons/fa"
import { FaVideo } from "react-icons/fa6"
import { LuScreenShare } from "react-icons/lu"
import { MdNotificationsActive, MdOutlineSupervisorAccount } from "react-icons/md"
import { SettingsButton, SettingsCategory } from "../../CategorySharedStyles"

const options = [
  { id: "Streamer Settings", label: "Settings", Icon: FaCog },
  { id: "Embedded Platforms", label: "My Video Embed", Icon: FaVideo },
  { id: "Platform Icons", label: "About Me Platforms", Icon: LuScreenShare },
  {
    id: "Streamer Account Linking",
    label: "Linked Accounts",
    Icon: MdOutlineSupervisorAccount,
  },
  {
    id: "Streamer Test Notifications",
    label: "Test Your Notifications",
    Icon: MdNotificationsActive,
  }
]

function StreamerSettings() {
  return (
    <SettingsCategory>
      <h3>Streamer Settings</h3>

      <ul>
        {options.map(({ id, label, Icon }) => (
          <li key={id}>
            <SettingsButton value={id}>
              <Icon /> <span>{label}</span>
            </SettingsButton>
          </li>
        ))}
      </ul>
    </SettingsCategory>
  )
}

export default StreamerSettings
