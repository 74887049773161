import { Button } from "@radix-ui/themes"
import { useDispatch } from "react-redux"
import { useToken } from "../../../contexts/TokenContext"
import { useUserProfile } from "../../../contexts/UserProfileContext"
import { getUserProfileFromToken } from "../../../services/GetUserProfileFromToken"

function BecomeDeveloperButton() {
  const dispatch = useDispatch()

  const { token } = useToken()
  const { setProfile } = useUserProfile()

  const handleBecomeDeveloper = async () => {
    try {
      if (!token) return

      dispatch({ type: "START_LOADING" })
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/developer/join`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )

      if (!response.ok) {
        dispatch({ type: "STOP_LOADING" })
        throw new Error("Failed to become a developer")
      }

      const profile = await getUserProfileFromToken(token)
      if (!profile) {
        throw new Error("User profile is null")
      }

      console.log("Full Response Data:", JSON.stringify(profile, null, 2)) // Stringify and pretty-print the data

      setProfile(profile)

      console.log("Successfully became a developer")
    } catch (error) {
      console.error("Error:", error)
    } finally {
      dispatch({ type: "STOP_LOADING" })
    }
  }

  return (
    <Button onClick={handleBecomeDeveloper} style={{ width: "100%" }}>
      Become a Developer (Admin Only)
    </Button>
  )
}

export default BecomeDeveloperButton
