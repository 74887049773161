import { Theme } from "@radix-ui/themes"
import { PropsWithChildren, useCallback, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useToken } from "../contexts/TokenContext"
import { useUserProfile } from "../contexts/UserProfileContext"
import AccountUnsuccessfulLinkDialog from "./AccountUnsuccessfulLinkDialog"
import { getUserProfileFromToken } from "./GetUserProfileFromToken"

export type AccountLinkingStatus = "alreadyLinked" | "accountOwned" | "success"

// TODO: Optimize
function TokenSetter({ children }: PropsWithChildren) {
  const navigate = useNavigate()
  const location = useLocation()

  const { token, setToken } = useToken()
  const { profile, setProfile, setProfileFetched } = useUserProfile()

  const clearToken = useCallback(() => {
    console.log("Clearing the token")
    setToken(null)
  }, [setToken])

  const handleLogout = useCallback(() => {
    console.log("Logging out your user was deleted")
    clearToken()
    setProfile(null)
    setProfileFetched(false)
    localStorage.removeItem("userProfile")
  }, [clearToken, setProfile, setProfileFetched])

  const fetchAndSetProfile = useCallback(
    async (token: string) => {
      console.log("Fetching profile...")
      try {
        const fetchedProfile = await getUserProfileFromToken(token)
        if (!fetchedProfile) {
          console.log("No profile found for token:", token)
          return clearToken()
        }

        setProfile(fetchedProfile)
        setProfileFetched(true)
      } catch (error) {
        console.error("Error fetching profile:", error)
        handleLogout() // Handle logout on error
      }
    },
    [clearToken, handleLogout, setProfile, setProfileFetched]
  )

  const handleProfileUpdate = useCallback(async () => {
    if (!token) return navigate(location.pathname, { replace: true })

    const queryParams = new URLSearchParams(location.search)
    const status = queryParams.get("profile_updated") as AccountLinkingStatus

    switch (status) {
      case "success":
        console.log("Profile Updated + Token - Fetching and Setting Profile")
        await fetchAndSetProfile(token)
        navigate(location.pathname, { replace: true })
        break

      case "accountOwned":
      case "alreadyLinked":
        navigate(location.pathname, { replace: true, state: { status } })
        break
    }
  }, [fetchAndSetProfile, location.pathname, location.search, navigate, token])

  // Detect and set token from hash
  useEffect(() => {
    const { hash, pathname } = location
    const tokenFromHash = hash.substring(1)

    // TODO: Use query params instead
    if (tokenFromHash) {
      console.log("Token found from hash, setting...", tokenFromHash)
      setToken(tokenFromHash)
      navigate(pathname, { replace: true })
    }
  }, [location, navigate, setToken, token])

  // Fetch profile if not already
  useEffect(() => {
    if (token && !profile) {
      console.log(
        "No Profile and we have a Token - Fetching and Setting Profile"
      )

      fetchAndSetProfile(token)
    }
  }, [fetchAndSetProfile, profile, token])

  // Handle any profile updates (e.g. linking)
  useEffect(() => {
    handleProfileUpdate()
  }, [handleProfileUpdate])

  return (
    <Theme
      appearance="dark"
      accentColor="yellow"
      radius="small"
      // TODO: Find a way to set a default bgcolor
      // other than the default black
      // hasBackground={false}
    >
      <AccountUnsuccessfulLinkDialog />

      {children}
    </Theme>
  )
}

export default TokenSetter
