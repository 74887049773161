import { CSSProperties } from "react"

export type MessageEventType =
  | "System"
  | "ChatRPG"
  | "TwitchChatRPG"
  | "YoutubeChatRPG"
  | "Twitch"
  | "Trovo"
  | "Tiktok"
  | "Kick"
  | "Youtube"
  | "TwitchEvent"
  | "TiktokEvent"
  | "YoutubeEvent"
  | "StreamlabsEvent"
  | "StreamelementsEvent"
  | "KickEvent"
  | "CustomEvent"
  | "None"

export type MessageSource = "announcement" | "user"

export interface MessageData extends Record<string, any> {
  type: string
  channel?: string
  color?: string
  user?: string
  badges?: any

  // TODO: Figure out how to standardize this
  data?: any
}

interface ChatMessageOptions {
  timestamp: string
  background?: CSSProperties["background"]
}

function generateId(): string {
  const timestamp = Date.now().toString(36)
  const randomNum = Math.random().toString(36).substring(2, 8)

  return `${timestamp}-${randomNum}`
}

class ChatMessage {
  private _id = generateId()

  type: MessageEventType
  source: MessageSource
  data: MessageData
  options: ChatMessageOptions

  constructor(
    type: MessageEventType,
    source: MessageSource,
    data: MessageData,
    options?: Partial<ChatMessageOptions>
  ) {
    this.type = type
    this.source = source
    this.data = data

    this.options = {
      ...options,
      timestamp: options?.timestamp ?? new Date().toISOString(),
    }
  }

  get key() {
    return this._id
  }
}

export default ChatMessage
