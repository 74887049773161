import {
  Avatar,
  Box,
  DropdownMenu,
  Flex,
  Skeleton,
  Text,
} from "@radix-ui/themes"
import { IoLogOutOutline, IoSettingsOutline } from "react-icons/io5"
import { useDispatch } from "react-redux"
import { styled } from "styled-components"
import { toggleSettings } from "../../store/reducers/settingsReducer"
import { useToken } from "../contexts/TokenContext"
import { useUserProfile } from "../contexts/UserProfileContext"
import LoginButtonWithAuth from "../ui/button/LoginButton"
import { useSelector } from "react-redux"
import { getAllExtensionJWT } from "../../store/slices/Auth"
import { useStreamerProfile } from "../contexts/StreamerProfileContext"

const routes = [
  { label: "Privacy Policy", href: "/privacy-policy" },
  { label: "Terms of Service", href: "/terms-of-service" },
]

function RightSection() {
  const dispatch = useDispatch()

  const { token, setToken } = useToken()
  const { profile, profileFetched, setProfile } = useUserProfile()
  // const { streamerProfile } = useStreamerProfile()
  // const getExtensionJWT = useSelector(getAllExtensionJWT)

  // const streamerPointsExtension = streamerProfile.extensions.find(
  //   ({ type, enabled }) => type === "Points" && enabled
  // )

  // const jwtToken = streamerPointsExtension ? getExtensionJWT(streamerPointsExtension.clientId) : null;
  // const shouldShowPoints = token && jwtToken && streamerPointsExtension;

  // // Add the JSX to display the iframe within the existing component layout
  // if (shouldShowPoints && streamerProfile) {
  //   console.log("Rendering iframe for streamer points extension");
  // }

  const handleLogout = () => {
    setToken(null)
    setProfile(null)
    localStorage.removeItem("token")
  }

  if (profile) {
    return (
      // <Flex align="center">
      //   <div>
      //     {
      //       shouldShowPoints && streamerProfile ? (
      //         <Box maxHeight="2rem" pl="1" asChild>
      //           <iframe
      //             src={`${streamerPointsExtension.buildUrl
      //               }?token=${getExtensionJWT(streamerPointsExtension.clientId)}`}
      //             className="embeddedProject"
      //             title="Embedded Page"
      //           // allowTransparency
      //           />
      //         </Box>
      //       ) : // Render nothing or a placeholder when profiles are not available
      //         null // or <ExtensionPlaceholder className="defaultSize" />
      //     }
      //   </div>

        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <UserAvatarButton
              src={profile.chatrpg_profilePicture}
              alt={profile.chatrpg_username}
              fallback={profile.chatrpg_username[0]}
              size="2"
              radius="full"
              mr="3"
            />
          </DropdownMenu.Trigger>

          <DropdownContent variant="soft" color="gray" align="end" sideOffset={5}>
            <DropdownMenu.Item asChild>
              <Flex gap="4" py="6" mb="-1">
                <Avatar
                  src={profile.chatrpg_profilePicture}
                  alt={profile.chatrpg_username}
                  fallback={profile.chatrpg_username[0]}
                  size="4"
                  radius="full"
                />
                <Text size="3" weight="bold">
                  {profile!.chatrpg_username}
                </Text>
              </Flex>
            </DropdownMenu.Item>

            <DropdownMenu.Separator />

            <DropdownMenu.Item onSelect={() => dispatch(toggleSettings())}>
              <IoSettingsOutline size={24} color="var(--chatrpg-logo-color)" />
              Settings
            </DropdownMenu.Item>

            <DropdownMenu.Item onSelect={handleLogout}>
              <IoLogOutOutline size={25} color="var(--chatrpg-logo-color)" />
              Logout
            </DropdownMenu.Item>

            <DropdownMenu.Separator />

            {routes.map((route) => (
              <DropdownMenu.Item key={route.label} asChild>
                <a href={route.href} target="_blank" rel="noopener noreferrer">
                  {route.label}
                </a>
              </DropdownMenu.Item>
            ))}

            {process.env.REACT_APP_VERSION && (
              <>
                <DropdownMenu.Separator />

                <DropdownMenu.Item disabled asChild>
                  <Box my="-2">
                    <Text size="1">
                      ChatRPG Build: {process.env.REACT_APP_VERSION}
                    </Text>
                  </Box>
                </DropdownMenu.Item>
              </>
            )}
          </DropdownContent>
        </DropdownMenu.Root>
      // </Flex>
    )
  }

  if (token && !profileFetched) {
    return (
      <Skeleton
        height="2rem"
        width="2rem"
        mr="3"
        style={{ borderRadius: "100%" }}
      />
    )
  }

  return <LoginButtonWithAuth />
}

const UserAvatarButton = styled(Avatar)`
  cursor: pointer;
  border: 2px solid transparent;
  transition: border-color 0.3s ease;

  &:hover {
    border: 2px solid #f8b426;
  }
`

const DropdownContent = styled(DropdownMenu.Content)`
  /* min-width: 13rem; */
  background-color: #18181b;

  .rt-DropdownMenuViewport {
    gap: 0.25rem;
  }

  .rt-DropdownMenuItem:not([data-disabled]):not(:first-child) {
    cursor: pointer;
  }
`

export default RightSection
