import { styled } from "styled-components"
import { useIntersectionObserver } from "usehooks-ts"
import Footer from "./components/Footer"
import NavigationBar from "./components/NavigationBar"
import Benefits from "./components/sections/Benefits"
import Features from "./components/sections/Features"
import Hero from "./components/sections/Hero"
import Integrations from "./components/sections/Integrations"
import MassiveCTA from "./components/sections/MassiveCTA"
import { media } from "./styled.utils"

import "./global.css"

const MainContent = styled.main`
  overflow-x: hidden;
  background-color: white;
  color: #0d1f2d;

  & > :last-child,
  & > div > :last-child {
    margin-bottom: 0 !important;
  }

  & > :not([hidden]) ~ :not([hidden]),
  & > div > :not([hidden]) ~ :not([hidden]) {
    margin-top: var(--space-2xl);
    margin-bottom: var(--space-2xl);

    ${media.lg`
      margin-top: var(--space-3xl);
      margin-bottom: var(--space-3xl);
    `}
  }
`

function App() {
  const { ref, isIntersecting } = useIntersectionObserver({
    rootMargin: "0px 0px -77% 0px",
  })

  return (
    <>
      <NavigationBar $dark={isIntersecting} />

      <MainContent>
        <Hero />

        <div ref={ref}>
          {/* Problem/Solution Section */}
          <Benefits />
          <Integrations />
          <Features />
          {/* Testimonials */}
          {/* FAQs? */}
          <MassiveCTA />
        </div>
      </MainContent>

      <Footer />
    </>
  )
}

export default App
