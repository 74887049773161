import { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import {
  addStreamerPageYoutubeVideoId,
  removeStreamerPageYoutubeVideoId,
} from "../../../store/slices/StreamerPage"
import { useToken } from "../../contexts/TokenContext"
import { useUserProfile } from "../../contexts/UserProfileContext"
import { useWebSocket } from "../../contexts/WebSocketContext"
import ChatMessage, { MessageData } from "../chatmessage/ChatMessageModel"

const MAX_MESSAGES = 150

// Well uhh i mean this works?? LMAO
let alternate = true
function getBackground(disableRotatingChatBackground?: boolean) {
  if (disableRotatingChatBackground) return

  alternate = !alternate
  return alternate ? "var(--panel-default-color)" : undefined
}

function checkMessage(message: unknown): message is ChatMessage {
  return message != null && message instanceof ChatMessage
}

function useChatWebSocket() {
  const dispatch = useDispatch()

  const { username } = useParams()

  const { socket, status } = useWebSocket()
  const { token } = useToken()
  const { profile } = useUserProfile()

  const [messages, setMessages] = useState<ChatMessage[]>([])
  const buffer = useRef<MessageData[]>([])

  // useRandomMessageGenerator(setMessages, 200) // Hook for random messages

  // Reset chat on streamer change
  useEffect(() => setMessages([]), [username])

  useEffect(() => {
    let message = ""

    switch (status) {
      case "connected":
        message = "Connected!"
        break

      case "disconnected":
        message = "Disconnected!"
        break

      case "error":
        message = "Error connecting to chat."
        break
    }

    if (message) {
      const statusMessage = new ChatMessage("System", "announcement", {
        type: "System",
        message,
      })

      setMessages((prev) => [...prev, statusMessage].slice(-MAX_MESSAGES))
    }
  }, [status])

  const handleMessage = useCallback(
    (data: MessageData) => {
      // If we are logged in but no profile data yet,
      // queue the message to the buffer for later
      if (token && !profile) {
        buffer.current.push(data)
        return null
      }

      const { settings } = profile ?? {}

      const background = getBackground(settings?.disableRotatingChatBackground)
      const defaultArgs = ["user", data, { background }] as const

      switch (data.type) {
        case "connectedClientsCount":
          const announcementData = {
            type: data.type,
            message: `Users Online: ${data.count}`,
          }

          return new ChatMessage("System", "announcement", announcementData)

        case "ChatRPG":
          if (settings?.disableChatRPGChat) return null
          return new ChatMessage("ChatRPG", ...defaultArgs)

        case "TwitchChatRPG":
          if (settings?.disableTwitchChat) return null
          return new ChatMessage("TwitchChatRPG", ...defaultArgs)

        case "YoutubeChatRPG":
          if (settings?.disableYoutubeChat) return null
          return new ChatMessage("YoutubeChatRPG", ...defaultArgs)

        case "Twitch":
          if (settings?.disableTwitchChat) return null
          return new ChatMessage("Twitch", ...defaultArgs)

        case "Trovo":
          return new ChatMessage("Trovo", ...defaultArgs)

        case "Tiktok":
          if (settings?.disableTiktokChat) return null
          return new ChatMessage("Tiktok", ...defaultArgs)

        case "Kick":
          if (settings?.disableKickChat) return null
          return new ChatMessage("Kick", ...defaultArgs)

        case "Youtube":
          if (settings?.disableYoutubeChat) return null
          return new ChatMessage("Youtube", ...defaultArgs)

        case "TwitchEvent":
          console.log(`Subscription data: ${JSON.stringify(data)}`)
          return new ChatMessage("TwitchEvent", "user", data)

        case "CustomEvent":
          console.log(`Custom Event Data is coming in: ${JSON.stringify(data)}`)
          return new ChatMessage("CustomEvent", "user", data)
          
        case "gift":
          return new ChatMessage("TiktokEvent", "user", data)

        case "YoutubeEvent":
          console.log("Just received a Youtube Event...", JSON.stringify(data))
          return new ChatMessage("YoutubeEvent", "user", data)

        case "TiktokEvent":
          console.log("Just received a Tiktok Event...", JSON.stringify(data))
          return new ChatMessage("TiktokEvent", "user", data)

        case "KickEvent":
          console.log("Just received a Kick Event...", JSON.stringify(data))
          return new ChatMessage("KickEvent", "user", data)

        case "StreamlabsEvent":
          console.log("Just received a Streamlabs Event...", JSON.stringify(data))
          return new ChatMessage("StreamlabsEvent", "user", data)

        case "StreamelementsEvent":
          console.log("Just received a Streamlabs Event...", JSON.stringify(data))
          return new ChatMessage("StreamelementsEvent", "user", data)

        case "System":
          const matches = /(offline)?! Video ?ID: (.*)$/i.exec(data.message)

          console.log(matches)
          if (matches) {
            const [, isStopped, videoId] = matches

            if (isStopped) {
              dispatch(removeStreamerPageYoutubeVideoId(videoId))
            } else {
              dispatch(addStreamerPageYoutubeVideoId(videoId))
            }
          }

          return new ChatMessage("System", "user", data)

        default:
          throw new Error(`Unhandled message type: ${data.type}`)
      }
    },
    [dispatch, profile, token]
  )

  useEffect(() => {
    const messageHandler = (event: MessageEvent) => {
      if (status !== "connected") return

      // logWithYellowBackground(
      //   `Received message in ChatWebSocketDisplay.tsx: ${event.data}`
      // )

      const messageData = JSON.parse(event.data)
      if (Array.isArray(messageData)) {
        const messages = messageData.map(handleMessage).filter(checkMessage)

        return setMessages((prev) =>
          [...prev, ...messages].slice(-MAX_MESSAGES)
        )
      }

      const message = handleMessage(messageData)
      if (message) {
        setMessages((prev) => [...prev, message].slice(-MAX_MESSAGES))
      }
    }

    if (status === "connected") {
      socket.addEventListener("message", messageHandler)
      return () => socket.removeEventListener("message", messageHandler)
    }

    // if (status === "disconnected") {
    //   socket.removeEventListener("message", messageHandler)
    //   setMessages([])
    // }
  }, [handleMessage, socket, status])

  // Flush buffer once profile data is loaded
  useEffect(() => {
    if (token && profile && buffer.current.length > 0) {
      const messages = buffer.current.map(handleMessage).filter(checkMessage)
      setMessages((prev) => [...prev, ...messages].slice(-MAX_MESSAGES))

      buffer.current = []
    }
  }, [handleMessage, profile, token])

  return { messages }
}

export default useChatWebSocket
