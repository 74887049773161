import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { styled } from "styled-components"
import { RootState } from "../../store"
import { setTheatreMode } from "../../store/reducers/theatreModeReducer"
import UsernamePrompt from "../auth/username/UsernamePromptBox"
import ChatArea from "../chat/ChatArea"
import { GlobalBadgeProvider } from "../contexts/GlobalBadgeContext"
import { useMobileContext } from "../contexts/MobileContext"
import { useOrientation } from "../contexts/OrientationContext"
import { useToken } from "../contexts/TokenContext"
import { useUserProfile } from "../contexts/UserProfileContext"
import { UserNotFound } from "../misc/404/UserNotFound"
import Sidebar from "../sidebar/Sidebar"
import TopBar from "../topbar/TopBar"
import { useStreamerProfile } from "./../contexts/StreamerProfileContext"
import ContentAreaContainer from "./ContentAreaContainer"
import PlayerArea from "./player/PlayerArea"

function UserPage() {
  const dispatch = useDispatch()

  const { isLandscape } = useOrientation()
  const { isMobile } = useMobileContext()

  const { token } = useToken()
  const { profile } = useUserProfile()
  const { status } = useStreamerProfile()

  const { isTheatreMode, isFullscreenMode } = useSelector(
    ({ theatreMode, fullscreenMode }: RootState) => ({
      isTheatreMode: theatreMode.isTheatreMode,
      isFullscreenMode: fullscreenMode.isFullscreenMode,
    })
  )

  useEffect(() => {
    dispatch(setTheatreMode(isLandscape))
  }, [dispatch, isLandscape])

  const isLoggedIn = token && !!profile?.chatrpg_username
  const hasFollowedStreamers = profile && profile.followedStreamers.length > 0
  const sidebarDisabled = profile?.settings?.disableSidebar
  const showSidebar = isLoggedIn && hasFollowedStreamers && !sidebarDisabled

  // TODO
  // Maybe we can render a loader here so we don't flash
  // the chat area when a streamer isn't found?
  // if (isLoading) return <LoadingSpinner />

  return (
    <Wrapper>
      <UsernamePrompt />

      {/* Show sidebar only if not in theatre or full-screen mode */}
      {!isTheatreMode && !isFullscreenMode && showSidebar ? <Sidebar /> : null}

      <ContentAreaContainer>
        {!isTheatreMode && !isFullscreenMode && <TopBar />}

        {status === "error" ? (
          <UserNotFound />
        ) : (
          <MainContent>
            {!isMobile && <PlayerArea />}

            {!isFullscreenMode && (
              <GlobalBadgeProvider>
                <ChatArea />
              </GlobalBadgeProvider>
            )}
          </MainContent>
        )}
      </ContentAreaContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  /* flex-direction: column; */
  min-width: 400px;
  height: 100vh;
  max-width: 100%;
  overflow: clip;
  background-color: #0e0e10;
`

const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1; /* Take remaining space after the top bar */
  height: calc(100vh - var(--top-bar-height));
  background-color: #0e0e10;
`

export default UserPage
