import { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import { setCurrentChatPlatform } from "../../../../../store/reducers/currentChatPlatformReducer"
import PLATFORMS from "../../../../config/platformConfig"
import { useStreamerProfile } from "../../../../contexts/StreamerProfileContext"
import { useUserProfile } from "../../../../contexts/UserProfileContext"
import UserDropdownMenu from "../../../../topbar/dropdown/UserDropDownMenu"

import "./PlatformSelector.css"

function PlatformSelector() {
  const dispatch = useDispatch()

  const { profile } = useUserProfile() // Access user profile
  const { streamerProfile } = useStreamerProfile() // Access streamer profile
  const isTwitchConnected = profile?.linkedAccounts?.Twitch && streamerProfile?.twitchId;

  // Set default platform based on Twitch connection
  const defaultPlatform = isTwitchConnected ? "Twitch" : "ChatRPG";


  const toggleRef = useRef<HTMLDivElement>(null)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [selectedPlatform, setSelectedPlatform] = useState<string | null>(
    "ChatRPG"
  )

  useEffect(() => {
    setSelectedPlatform(defaultPlatform) // Set the local state
    dispatch(setCurrentChatPlatform(defaultPlatform)) // Dispatch to Redux store
  }, [defaultPlatform, dispatch])

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen)
  }

  const handleSelect = (platform: string) => {
    setSelectedPlatform(platform)
    setDropdownOpen(false) // Optionally close the dropdown on selection
    dispatch(setCurrentChatPlatform(platform)) // Dispatch the action here
  }

  const getBadgeSource = () => {
    if (selectedPlatform && PLATFORMS[selectedPlatform]) {
      return PLATFORMS[selectedPlatform].badge || ""
    }

    return PLATFORMS.ChatRPG.badge // Default badge if no platform is selected or if PLATFORMS[selectedPlatform] is undefined
  }

  const isChatRPGSelected = selectedPlatform === "ChatRPG"

  const menuItems = (
    <div className="dropdown-category">
      {/* Filter linked accounts with write: true */}
      {profile
        ? Object.entries(profile.linkedAccounts).map(([platform]) => {
          if (
            !PLATFORMS[platform]?.write ||
            (platform === "Twitch" && !streamerProfile?.twitchId)
          )
            return null

          if (
            !PLATFORMS[platform]?.write ||
            (platform === "Youtube" && !streamerProfile?.youtubeChannelId)
          )
            return null;

          const isSelected = selectedPlatform === platform

          return (
            <div
              key={platform}
              className={`dropdown-item ${isSelected ? "dropdown-selected-item" : ""
                }`}
              onClick={() => handleSelect(platform)}
            >
              <img
                src={PLATFORMS[platform].badge || ""}
                alt={platform}
                className="dropdown-platform-badge"
              />
            </div>
          )
        })
        : null}

      {/* Conditionally include ChatRPG badge based on premade status */}
      {(!streamerProfile?.premade || streamerProfile?.youtubeChannelId) && (
        <div
          key="ChatRPG"
          className={`dropdown-item ${isChatRPGSelected ? "dropdown-selected-item" : ""
            }`}
          onClick={() => handleSelect("ChatRPG")}
        >
          <img
            src={PLATFORMS.ChatRPG.badge}
            alt="ChatRPG"
            className="dropdown-platform-badge"
          />
        </div>
      )}
    </div>
  )

  if (!profile) return null

  return (
    <div ref={toggleRef} onClick={toggleDropdown}>
      <PlatformIcon src={getBadgeSource()} alt="ChatRPG badge" />

      <div className="icon-placeholder-new">
        <UserDropdownMenu
          isOpen={dropdownOpen}
          toggle={toggleDropdown}
          menuItems={menuItems}
          alignment="left"
          verticalAlignment="up"
          toggleRef={toggleRef}
          additionalClass="dropdown-menu-platformselector"
        />
      </div>
    </div>
  )
}

const PlatformIcon = styled.img`
  object-fit: contain;
  cursor: pointer;
  display: flex;
  align-self: center;
  justify-content: center;
`

export default PlatformSelector
