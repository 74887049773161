import axios from "axios"
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useDispatch } from "react-redux"
import { useToken } from "./TokenContext"

type FollowedStreamer = {
  chatrpg_id: string // Unique identifier for the followed streamer
  chatrpg_username: string // Username of the followed streamer
  streamerObjectId: string
  // Add any other relevant fields here
}

export type LinkedAccount = {
  platformUsername: string
  isStreamer: boolean
}

export interface UserSettings {
  disablePlayer: boolean
  disableSidebar: boolean
  disableEmbeds: boolean
  disableTiktokChat: boolean
  disableYoutubeChat: boolean
  disableKickChat: boolean
  disableTwitchChat: boolean
  disableChatRPGChat: boolean
  disableRotatingChatBackground: boolean
  // Add more settings as needed
}

export type UserProfile = {
  chatrpg_username: string
  chatrpg_id: string // Add the user's ID if needed
  chatrpg_profilePicture?: string // Add the profile picture URL
  twitchScopes: string[]
  isStreamer: boolean
  isDeveloper: boolean
  administrator: boolean
  version?: string
  followedStreamers: FollowedStreamer[] // Array of followed streamers
  linkedAccounts: Record<string, LinkedAccount>
  settings: UserSettings
}

type UserProfileContextType = {
  profile: UserProfile | null
  setProfile: Dispatch<SetStateAction<UserProfile | null>>
  profileFetched: boolean
  setProfileFetched: Dispatch<SetStateAction<boolean>>
  updateSettings: (settings: Partial<UserSettings>) => Promise<void>
  reorderStreamerBubble: (from: number, to: number) => FollowedStreamer[]
}

const UserProfileContext = createContext<UserProfileContextType | null>(null)

export function useUserProfile() {
  const context = useContext(UserProfileContext)

  if (!context) {
    throw new Error("useUserProfile must be used within a UserProfileProvider")
  }

  return context
}

function reorderArray(arr: any[], from: number, to: number) {
  const clonedArr = [...arr]

  const [streamer] = clonedArr.splice(from, 1)
  clonedArr.splice(to, 0, streamer)

  return clonedArr
}

function UserProfileProvider({ children }: PropsWithChildren) {
  const dispatch = useDispatch()
  const { token } = useToken()

  const [profile, setProfile] = useState<UserProfile | null>(null)
  const [profileFetched, setProfileFetched] = useState(false)

  useEffect(() => {
    console.log("Profile updated:", profile)
  }, [profile])

  const updateSettings = useCallback(
    async (settings: Partial<UserSettings>) => {
      dispatch({ type: "START_LOADING" })
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/profile/options/settings`,
        settings,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      dispatch({ type: "STOP_LOADING" })

      console.log(response.data)

      setProfile(response.data)
    },
    [dispatch, token]
  )

  const reorderStreamerBubble = useCallback(
    (from: number, to: number) => {
      if (!profile) return []

      setProfile((prev) => {
        if (!prev) return null

        return {
          ...prev,
          followedStreamers: reorderArray(prev.followedStreamers, from, to),
        }
      })

      return reorderArray(profile.followedStreamers, from, to)
    },
    [profile]
  )

  const value = useMemo(
    () => ({
      profile,
      setProfile,
      profileFetched,
      setProfileFetched,
      updateSettings,
      reorderStreamerBubble,
    }),
    [profile, profileFetched, reorderStreamerBubble, updateSettings]
  )

  return (
    <UserProfileContext.Provider value={value}>
      {children}
    </UserProfileContext.Provider>
  )
}

export default UserProfileProvider
