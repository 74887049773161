import { Box, Button, Flex, IconButton, TextField } from "@radix-ui/themes"
import axios from "axios"
import { ReactNode, useState } from "react"
import { MdOutlineRestore } from "react-icons/md"
import { PiWarningCircleBold } from "react-icons/pi"
import { VscRefresh } from "react-icons/vsc"
import { PlatformDetails, PlatformKey } from "../../config/platformConfig"
import { useToken } from "../../contexts/TokenContext"
import {
  IconImg,
  Platform,
} from "./panels/usersettings/accountlinking/AccountLinkingOptionsStyles"
import SaveLinkButton from "./panels/usersettings/accountlinking/SaveLinkButton"
import UnlinkButton, {
  UnlinkButtonProps,
} from "./panels/usersettings/accountlinking/UnlinkButton"

interface LinkablePlatformProps
  extends Partial<Pick<UnlinkButtonProps, "streamer" | "isUnlinkable">> {
  platform: PlatformKey
  details: PlatformDetails
  isLinked: boolean
  buttonLabel: ReactNode
  inputPlaceholder?: string
  value?: string
  onOauthLink?: () => void
}

// TODO: This should be moved somewhere more appropriate
// Also, can still be modularized further ? thonk
// We need to separate this into oauth and non-oauth comps
// for starters, but it should still be visually consistent
// Otherwise, this will get out of hand pretty quickly
function LinkablePlatform({
  platform,
  details,
  isLinked,
  value: nonOauthLinkedValue,
  buttonLabel,
  inputPlaceholder,
  streamer,
  isUnlinkable,
  onOauthLink,
}: LinkablePlatformProps) {
  const { token } = useToken()

  const { color, badge, refresh, fakeLink, streamerFakeLink } = details

  const isFakeLink = fakeLink || (streamerFakeLink && streamer)

  const [username, setUsername] = useState(nonOauthLinkedValue ?? "")

  const handleLinkAccount = () => {
    if (isLinked || isFakeLink) return

    onOauthLink?.()
  }

  const handleInputChange = (value: any) => {
    setUsername(value)
  }

  const handleReset = (value?: string) => {
    handleInputChange(value ?? nonOauthLinkedValue)
  }

  const handleRefresh = () => {
    const url = `${
      process.env.REACT_APP_WEBSOCKET_SERVER_URL
    }/streamer/${platform.toLowerCase()}/connect`

    axios
      .post(url, {}, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        // Response handling
      })
      .catch((error) => {
        // Error handling
      })
  }

  return (
    <Button
      size="3"
      radius="large"
      onClick={handleLinkAccount}
      disabled={isLinked}
      asChild
    >
      <Platform
        $color={isLinked ? "" : color ?? ""}
        style={{
          cursor: isFakeLink ? "auto" : isLinked ? "not-allowed" : "pointer",
          border: isLinked && !isFakeLink ? `2px solid ${color}` : undefined,
        }}
      >
        <IconImg src={badge} alt={platform} />

        <Flex flexGrow="1" align="center" justify="center" gap="3" as="span">
          {isFakeLink ? (
            <TextField.Root
              size={{ initial: "2", md: "3" }}
              placeholder={inputPlaceholder}
              value={username}
              onChange={(e) => {
                handleInputChange(e.currentTarget.value)
              }}
              onClick={(e) => e.stopPropagation()}
              style={{ width: "100%" }}
            >
              {isLinked && username !== nonOauthLinkedValue && (
                <TextField.Slot side="right">
                  <IconButton
                    variant="ghost"
                    color="gray"
                    title="Reset"
                    onClick={() => handleReset()}
                  >
                    <MdOutlineRestore size="22" />
                  </IconButton>
                </TextField.Slot>
              )}
            </TextField.Root>
          ) : (
            buttonLabel
          )}
        </Flex>

        <Flex as="span" align="center" gap="1">
          {isFakeLink && (username || isLinked) && (
            <SaveLinkButton
              platform={platform}
              data={username}
              // TODO: Refactor
              streamer={streamer}
              disabled={!username || username === nonOauthLinkedValue}
            />
          )}

          {(!isFakeLink || nonOauthLinkedValue) && isLinked && (
            <Box ml={isFakeLink ? "2" : "0"}>
              <UnlinkButton
                platform={platform}
                isUnlinkable={isUnlinkable ?? isLinked}
                streamer={streamer}
                onSuccess={() => setUsername("")}
              />
            </Box>
          )}

          {refresh && isLinked ? (
            <IconButton
              variant="ghost"
              color="gray"
              ml={isLinked ? "0" : "2"}
              onClick={() => handleRefresh()}
              title="Refresh Chat"
            >
              <VscRefresh size="23" color="white" />
            </IconButton>
          ) : null}

          {!isLinked && (
            <Box as="span" ml={username ? "4" : "1"} pr="1">
              <PiWarningCircleBold size="22" color="var(--yellow-a10)" />
            </Box>
          )}
        </Flex>
      </Platform>
    </Button>
  )
}

export default LinkablePlatform
