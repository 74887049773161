import { Flex } from "@radix-ui/themes"
import {
  ChangeEvent,
  forwardRef,
  Ref,
  TextareaHTMLAttributes,
  useLayoutEffect,
  useRef,
} from "react"
import styled from "styled-components"
import PlatformSelector from "../platformSelector/PlatformSelector"
import calculateNodeHeight from "./calculateNodeHeight"
import getSizingData, { SizingData } from "./getSizingData"
import {
  useComposedRef,
  useFontsLoadedListener,
  useWindowResizeListener,
} from "./hooks"
import { noop } from "./utils"

type TextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement>

interface Style
  extends Omit<NonNullable<TextAreaProps["style"]>, "maxHeight" | "minHeight"> {
  height?: number
}

export type TextareaHeightChangeMeta = {
  rowHeight: number
}

export interface TextAreaAutoSizeProps extends Omit<TextAreaProps, "style"> {
  maxRows?: number
  minRows?: number
  onHeightChange?: (height: number, meta: TextareaHeightChangeMeta) => void
  cacheMeasurements?: boolean
  style?: Style
}

const TextAreaAutoSize = forwardRef<HTMLTextAreaElement, TextAreaAutoSizeProps>(
  (
    {
      cacheMeasurements,
      maxRows,
      minRows,
      onChange = noop,
      onHeightChange = noop,
      ...props
    },
    userRef: Ref<HTMLTextAreaElement>
  ) => {
    const isControlled = props.value !== undefined
    const libRef = useRef<HTMLTextAreaElement | null>(null)
    const ref = useComposedRef(libRef, userRef)
    const heightRef = useRef(0)
    const measurementsCacheRef = useRef<SizingData>()

    const resizeTextarea = () => {
      const node = libRef.current!
      const nodeSizingData =
        cacheMeasurements && measurementsCacheRef.current
          ? measurementsCacheRef.current
          : getSizingData(node)

      if (!nodeSizingData) {
        return
      }

      measurementsCacheRef.current = nodeSizingData

      const [height, rowHeight] = calculateNodeHeight(
        nodeSizingData,
        node.value || node.placeholder || "x",
        minRows,
        maxRows
      )

      if (heightRef.current !== height) {
        console.log("Textarea height changed:", height) // Log statement here
        heightRef.current = height
        node.style.setProperty("height", `${height}px`, "important")
        onHeightChange(height, { rowHeight })
      }
    }

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      if (!isControlled) {
        resizeTextarea()
      }
      onChange(event)
    }

    useLayoutEffect(resizeTextarea)
    useWindowResizeListener(resizeTextarea)
    useFontsLoadedListener(resizeTextarea)

    return (
      <Wrapper>
        <PlatformSelectorContainer
          justify="center"
          align="center"
          position="absolute"
        >
          <PlatformSelector />
        </PlatformSelectorContainer>

        <textarea {...props} onChange={handleChange} ref={ref} />
      </Wrapper>
    )
  }
)

TextAreaAutoSize.displayName = "TextAreaAutoSize"

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-color: #18181b; /* Match textarea background */
  border-radius: 0.25rem; /* Match textarea border-radius */
  box-shadow: 0 0 0 1px #c0c0c0; /* Match textarea box-shadow */
  line-height: 1.25;
`

const PlatformSelectorContainer = styled(Flex)`
  left: 7px; /* Space from the left edge of the container */
  top: 7px; /* Align to the top with some space */
  width: 26px; /* Increased size */
  height: 26px; /* Increased size */
  z-index: 2; /* Ensure it's above the textarea */
`

export default TextAreaAutoSize
