// src/MobileContext.tsx
import React, { ReactNode, createContext, useContext, useState, useEffect } from 'react';

interface MobileContextProps {
    isMobile: boolean;
}

interface MobileContextProviderProps {
    children: ReactNode;  // Define the children prop here
}

const MobileContext = createContext<MobileContextProps | undefined>(undefined);

export const useMobileContext = () => {
    const context = useContext(MobileContext);
    if (!context) {
        throw new Error('useMobileContext must be used within a MobileContextProvider');
    }
    return context;
};

const MobileContextProvider: React.FC<MobileContextProviderProps> = ({ children }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 615);

    useEffect(() => {
        const handleResize = () => {            
            setIsMobile(window.innerWidth <= 615);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <MobileContext.Provider value={{ isMobile }}>
            {children}
        </MobileContext.Provider>
    );
};

export { MobileContext, MobileContextProvider };
