// src/contexts/TokenContext.tsx
import React, { ReactNode, createContext, useState, useEffect, useRef } from 'react';

interface TokenContextProps {
    token: string | null;
    setToken: (token: string | null) => void;
}

interface MobileContextProviderProps {
    children: ReactNode;  // Define the children prop here
}

export const TokenContext = createContext<TokenContextProps>({
    token: null,
    setToken: () => { },
});

export const TokenContextProvider: React.FC<MobileContextProviderProps> = ({ children }) => {
    const [token, setToken] = useState<string | null>(() => localStorage.getItem('token'));

    const isInitialMount = useRef(true);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            if (token) {
                localStorage.setItem('token', token);
            } else {
                localStorage.removeItem('token');
            }
        }
    }, [token]);
    
    console.log(token);

    return (
        <TokenContext.Provider value={{ token, setToken }}>
            {children}
        </TokenContext.Provider>
    );
};

export const useToken = () => {
    const context = React.useContext(TokenContext);
    if (context === undefined) {
        throw new Error('useToken must be used within a TokenContextProvider');
    }
    return context;
};