import { Box, Text } from "@radix-ui/themes"
import { ForwardedRef, forwardRef } from "react"
import styled, { css } from "styled-components"
import { useUserProfile } from "../../contexts/UserProfileContext"
import ChatBadge from "./ChatBadge"
import ChatMessage from "./ChatMessage"
import ChatMessageModel from "./ChatMessageModel"
import ChatUsername from "./ChatUsername"

interface ChatLineProps {
  message: ChatMessageModel
  // index: number
}

function ChatLine(
  { message }: ChatLineProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const { profile } = useUserProfile()
  // console.log("THIS IS OUR MESSAGE IN REACT " + messageModel.data.message + " AND OUR BADGES " + JSON.stringify(messageModel.data.badges));
  // console.log("HERE IS THE ENTIRE DATA " + JSON.stringify(messageModel));

  const isMentioned = Boolean(
    message.data.message &&
      profile &&
      profile.chatrpg_username &&
      message.data.message
        .toLowerCase()
        .includes(profile.chatrpg_username.toLowerCase())
  )

  const finalUsernameColor = message.data.color || "#ff0000" // #ff0000 is the hex code for red

  return (
    <Wrapper
      px="2"
      py="1"
      $mentioned={isMentioned}
      // data-index={index}
      ref={ref}
      asChild
      style={{ background: message.options.background }}
    >
      <Text>
        <ChatBadge platform={message.type} badges={message.data.badges} />

        {message.data.user && (
          <ChatUsername
            username={message.data.user}
            color={finalUsernameColor}
          />
        )}

        <ChatMessage
          message={message.data.message}
          displayStyle={message.type === "System" ? "system" : "regular"}
        />
      </Text>
    </Wrapper>
  )
}

const Wrapper = styled(Box)<{ $mentioned: boolean }>`
  line-height: 1.25;

  ${({ $mentioned }) =>
    $mentioned &&
    css`
      background-color: rgba(225, 50, 50, 0.2);
    `}

  & > span {
    vertical-align: middle;
  }
`

export default forwardRef(ChatLine)
