// src/hooks/useTestAlerts.js
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { useToken } from '../../../../../contexts/TokenContext';

const useTestAlerts = () => {
    const dispatch = useDispatch();
    const { token } = useToken();

    const sendFakeTwitchSubscription = async () => {
        dispatch({ type: "START_LOADING" });
        try {
            console.log("STARTING LOAD");
            await axios.post(`${process.env.REACT_APP_WEBSOCKET_SERVER_URL}/admin/faketwitchsubscription`, {
                twitchId: '48465437',
                chatrpgId: 'CRPG1COWSEP'
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            console.log("ENDING LOAD!");
            dispatch({ type: "STOP_LOADING" });
            return { success: true, message: "Test subscription alert sent successfully!" };
        } catch (error) {
            dispatch({ type: "STOP_LOADING" });
            console.error("Error sending test subscription alert:", error);
            return { success: false, message: "Failed to send test subscription alert" };
        }
    };

    const sendFakeTwitchBits = async () => {
        dispatch({ type: "START_LOADING" });
        try {
            await axios.post(`${process.env.REACT_APP_WEBSOCKET_SERVER_URL}/admin/faketwitchbits`, {
                twitchId: '48465437',
                chatrpgId: 'CRPG1COWSEP'
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            dispatch({ type: "STOP_LOADING" });
            return { success: true, message: "Test bits alert sent successfully!" };
        } catch (error) {
            dispatch({ type: "STOP_LOADING" });
            console.error("Error sending test bits alert:", error);
            return { success: false, message: "Failed to send test bits alert" };
        }
    };

    const sendFakeTwitchChannelPoints = async () => {
        dispatch({ type: "START_LOADING" });
        try {
            await axios.post(`${process.env.REACT_APP_WEBSOCKET_SERVER_URL}/admin/faketwitchpoints`, {
                twitchId: '48465437',
                chatrpgId: 'CRPG1COWSEP'
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            dispatch({ type: "STOP_LOADING" });
            return { success: true, message: "Test channel points alert sent successfully!" };
        } catch (error) {
            dispatch({ type: "STOP_LOADING" });
            console.error("Error sending test bits alert:", error);
            return { success: false, message: "Failed to send test bits alert" };
        }
    };

    const sendFakeYouTubeSuperChat = async () => {
        dispatch({ type: "START_LOADING" });
        try {
            await axios.post(`${process.env.REACT_APP_WEBSOCKET_SERVER_URL}/admin/fakeyoutubesuperchat`, {
                youtubeChannelId: 'YC123456789', // Assuming you need a channel ID
                amount: '5', // Assuming amount in USD
                message: 'This is a fake super chat'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            dispatch({ type: "STOP_LOADING" });
            return { success: true, message: "Test YouTube Super Chat alert sent successfully!" };
        } catch (error) {
            dispatch({ type: "STOP_LOADING" });
            return { success: false, message: "Failed to send YouTube Super Chat alert" };
        }
    };

    const sendFakeTikTokGift = async () => {
        dispatch({ type: "START_LOADING" });
        try {
            await axios.post(`${process.env.REACT_APP_WEBSOCKET_SERVER_URL}/admin/faketiktokgift`, {
                tikTokUserId: 'TT123456789', // Assuming you need a user ID
                giftName: 'Rose', // Assuming a gift name
                quantity: 10 // Assuming quantity of gifts
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            dispatch({ type: "STOP_LOADING" });
            return { success: true, message: "Test TikTok gift alert sent successfully!" };
        } catch (error) {
            dispatch({ type: "STOP_LOADING" });
            return { success: false, message: "Failed to send TikTok gift alert" };
        }
    };

    const sendFakeTikTokSubscriber = async () => {
        dispatch({ type: "START_LOADING" });
        try {
            await axios.post(`${process.env.REACT_APP_WEBSOCKET_SERVER_URL}/admin/faketiktoksubscriber`, {
                tikTokUserId: 'TT123456789', // Assuming you need a user ID
                giftName: 'Rose', // Assuming a gift name
                quantity: 10 // Assuming quantity of gifts
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            dispatch({ type: "STOP_LOADING" });
            return { success: true, message: "Test TikTok gift alert sent successfully!" };
        } catch (error) {
            dispatch({ type: "STOP_LOADING" });
            return { success: false, message: "Failed to send TikTok gift alert" };
        }
    };


    const sendFakeYouTubeSponsor = async () => {
        dispatch({ type: "START_LOADING" });
        try {
            await axios.post(`${process.env.REACT_APP_WEBSOCKET_SERVER_URL}/admin/fakeyoutubesponsor`, {
                youtubeChannelId: 'YC123456789', // Assuming you need a channel ID
                sponsorLevel: 'Gold', // Assuming levels of sponsorship
                duration: '1 month' // Assuming duration of sponsorship
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            dispatch({ type: "STOP_LOADING" });
            return { success: true, message: "Test YouTube sponsor alert sent successfully!" };
        } catch (error) {
            dispatch({ type: "STOP_LOADING" });
            console.error("Error sending test sponsor alert:", error);
            return { success: false, message: "Failed to send YouTube sponsor alert" };
        }
    };

    return {
        sendFakeTwitchSubscription,
        sendFakeTwitchBits,
        sendFakeYouTubeSuperChat,
        sendFakeTikTokGift,
        sendFakeYouTubeSponsor,
        sendFakeTikTokSubscriber,
        sendFakeTwitchChannelPoints
    };
};

export default useTestAlerts;
