const BASE_URL = process.env.REACT_APP_SERVER_URL

export const authHandler = (
  platform: string,
  action: string,
  token: string | null,
  currentRoute: string
) => {
  console.log(
    `Auth handler triggered with platform: ${platform} and action: ${action}`
  )
  const encodedRoute = encodeURIComponent(currentRoute)
  const encodedRouteTest = encodeURIComponent(`${window.location.origin}${currentRoute}`);
  console.log("This is the full URL for encoded route: " + encodedRouteTest);
  console.log("Encoded Current Route:", encodedRoute)

  // Ensure platform is in lowercase
  const platformLower = platform.toLowerCase()

  const url = `${BASE_URL}/auth/${platformLower}/${action}?jwt=${token}&redirect=${encodedRouteTest}`
  console.log(`URL: ${url}`)
  
  window.location.href = url
}
