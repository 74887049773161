// src/components/StreamerActiveDescriptionBox.js
import React from 'react';
import { useStreamerProfile } from '../../../../contexts/StreamerProfileContext';
import './ProfileSection.css'; // New CSS file for styling
import PLATFORMS from '../../../../config/platformConfig';
import { verifiedBadge } from '../../../../config/imageConfig';

const ProfileSection = () => {
    const { streamerProfile } = useStreamerProfile();

    if (!streamerProfile) return null;

    const twitchBadge = PLATFORMS['Twitch'].badge;

    return (
        <div className="profileSection">
            <img src={streamerProfile.chatrpg_profilePicture} alt="Profile" className="profilePicture" />
            <div className="userInfo">
                <div className="topSection">
                    <div className="username">{streamerProfile.chatrpg_username}</div>
                    {streamerProfile.chatrpg_partner && (
                        <img src={verifiedBadge} alt="Verified" className="infobox-verified-badge" />
                    )}
                    {streamerProfile.premade && (
                        <div className="twitch-badge">
                            <img src={twitchBadge} alt="Twitch" />
                            <span>Twitch Only</span>
                        </div>
                    )}
                </div>
                <div className="followerCount">{streamerProfile.followers?.toString() || '0'} followers</div>
                <div className="streamerDescription">Streamer's description here...</div>
            </div>
        </div>
    );
};

export default ProfileSection;
