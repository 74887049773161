import store from '../../../../../../store';
import { updateStreamerPagePanels } from '../../../../../../store/slices/StreamerPage';
import { Panel } from '../../../../../contexts/StreamerProfileContext';

const handlePanelSubmit = async (
    title: string,
    panelId: string,
    selectedFile: File | null,
    streamerProfile: { panels: Panel[] } | null, // Adjust this type according to your context
    // setStreamerProfile: React.Dispatch<React.SetStateAction<any>>,
    token: string | null,
    dispatch: Function,
    existingPanel: Panel | undefined, // Add this parameter
    setTitle: React.Dispatch<React.SetStateAction<string>>, // Add this to reset title
    setLinkUrl: React.Dispatch<React.SetStateAction<string>>, // Add this to reset linkUrl
    setDescription: React.Dispatch<React.SetStateAction<string>>, // Add this to reset description
    setPanelId: React.Dispatch<React.SetStateAction<string>>,
    setSelectedFile: React.Dispatch<React.SetStateAction<File | null>>,
    linkUrl: string,  // Added parameter
    description: string  // Added parameter
) => {
    if (!token) {
        console.error('No token provided');
        return;
    }

    let imageUrl = existingPanel?.imageUrl || ''; // Use existing imageUrl if no new file is selected
    let errorOccurred = false;

    dispatch({ type: 'START_LOADING' });

    const panelExists = streamerProfile?.panels.some(panel => panel.id === panelId);

    if (selectedFile) {
        const formData = new FormData();
        formData.append('image', selectedFile);
        formData.append('panelId', panelId);

        try {
            const uploadResponse = await fetch(`${process.env.REACT_APP_CDN_URL}/upload`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            });

            const uploadData = await uploadResponse.json();
            console.log('File upload response:', uploadData);
            imageUrl = uploadData.imageUrl;
        } catch (error) {
            errorOccurred = true;
            setTitle(`Error Uploading File ${error}`);
            console.error('Error uploading file:', error);
            dispatch({ type: 'STOP_LOADING' });
        }
    }

    if (!errorOccurred) {
        const panelData = {
            title,
            id: panelId,
            position: '1', // Consider dynamic positioning
            imageUrl,
            linkUrl: linkUrl,
            description: description
        };
        console.log("Sending Panel Data:", panelData); // Log data being sent

        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/streamer/addpanel`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(panelData),
                credentials: 'include',
            });

            const responseData = await response.json();
            console.log('Panel saved:', responseData);
            if (responseData && responseData.panels) {
                store.dispatch(updateStreamerPagePanels(responseData.panels));

                if (!panelExists) {
                    setTitle('');
                    setLinkUrl('');
                    setDescription('');
                    setPanelId(Date.now().toString()); // Uncomment if you need to reset the panel ID
                    setSelectedFile(null);  // Reset the selectedFile
                }
            }
            dispatch({ type: 'STOP_LOADING' });
        } catch (error) {
            console.error('Error saving panel:', error);
            dispatch({ type: 'STOP_LOADING' });
        }
    }
};

export default handlePanelSubmit;
