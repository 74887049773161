import { ScrollArea } from "@radix-ui/themes"
import { Link } from "react-router-dom"
import styled from "styled-components"
import SidebarBubbles from "./Bubbles"

import logo from "../../assets/logo.png"

function Sidebar() {
  return (
    <Wrapper>
      <BrandLink to="/browse">
        <Logo src={logo} alt="Logo" />
      </BrandLink>

      <Divider />

      <ScrollWrapper>
        <SidebarBubbles />
      </ScrollWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  flex-shrink: 0;
  width: var(--sidebar-width);
  background-color: var(--sidebar-color);
  color: #ecf0f1;

  display: flex;
  flex-direction: column;
`

const BrandLink = styled(Link)`
  height: calc(var(--top-bar-height) + 0.125rem);
  display: flex;
  justify-content: center;
  align-items: center;
`

const Logo = styled.img`
  max-height: 1.875rem;
  max-width: 1.875rem;
`

const Divider = styled.hr`
  border: 0;
  height: 1px;
  background: var(--top-bar-border-color);
  margin: 0;
`

const ScrollWrapper = styled(ScrollArea)`
  .rt-ScrollAreaScrollbar {
    visibility: hidden;
  }
`

export default Sidebar
