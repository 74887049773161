// src/components/InfoBox.js
import './InfoBox.css';
import React from 'react';
import { useStreamerProfile } from '../../../contexts/StreamerProfileContext';
import InteractiveSection from './interactive/InteractiveSection';
import ProfileSection from './profile/ProfileSection';

const InfoBox = () => {
    const { streamerProfile } = useStreamerProfile();


    if (!streamerProfile) return null;

    return (
        <div className="infoBox">
            <ProfileSection /> {/* Use StreamerActiveDescriptionBox component */}
            <InteractiveSection /> {/* Use InteractiveSection component */}
        </div>
    );
};

export default InfoBox;

// src/components/InfoBox.js




