import axios from "axios"

export async function getExtensionQueue(token: string | null) {
  if (!token) throw new Error("Unauthorized.")

  const response = await axios.get(
    `${process.env.REACT_APP_SERVER_URL}/developer/getqueue`,
    { headers: { Authorization: `Bearer ${token}` } }
  )

  return response.data
}

export async function approveExtension(
  clientId: string,
  chatrpg_id: string,
  token: string | null
) {
  if (!token) throw new Error("Unauthorized.")

  const response = await axios.post(
    `${process.env.REACT_APP_SERVER_URL}/developer/approve`,
    { clientId, chatrpg_id },
    { headers: { Authorization: `Bearer ${token}` } }
  )

  return response.data
}

export async function rejectExtension(
  clientId: string,
  chatrpg_id: string,
  reason: string,
  token: string | null
) {
  if (!token) throw new Error("Unauthorized.")

  const response = await axios.post(
    `${process.env.REACT_APP_SERVER_URL}/developer/reject`,
    { clientId, chatrpg_id, reason },
    { headers: { Authorization: `Bearer ${token}` } }
  )

  return response.data
}
