import { Button } from "@radix-ui/themes"
import { ComponentPropsWithoutRef } from "react"
import { useDispatch } from "react-redux"
import { setUserStreamerProfile } from "../../../../../../store/reducers/userStreamerProfileReducer"
import { PlatformKey } from "../../../../../config/platformConfig"
import { useToken } from "../../../../../contexts/TokenContext"
import { useUserProfile } from "../../../../../contexts/UserProfileContext"
import { linkAccount } from "./LinkAccount"

interface SaveLinkButtonProps extends ComponentPropsWithoutRef<typeof Button> {
  platform: PlatformKey
  data: string
  streamer?: boolean
}

function SaveLinkButton({
  platform,
  data,
  streamer,
  ...props
}: SaveLinkButtonProps) {
  const dispatch = useDispatch()

  const { token } = useToken()
  const { setProfile } = useUserProfile()

  const handleSaveAccount = async () => {
    if (!data) return

    dispatch({ type: "START_LOADING" })
    try {
      const response = await linkAccount(platform, data, token)
      console.log(`Successfully saved ${platform} username: ${data}`)

      if (streamer) {
        dispatch(setUserStreamerProfile(response.streamer))
      } else setProfile(response.streamer)
    } catch (error) {
      console.error(`Error saving ${platform} username:`, error)
    } finally {
      dispatch({ type: "STOP_LOADING" })
    }
  }

  return (
    <Button {...props} onClick={handleSaveAccount}>
      Save
    </Button>
  )
}

export default SaveLinkButton
