import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface EmbeddedPlayerState {
  index: number
  platform: string
  url: string
}

const initialState: EmbeddedPlayerState = {
  index: 0,
  platform: "",
  url: "",
}

const embeddedPlayer = createSlice({
  name: "embeddedPlayer",
  initialState,
  reducers: {
    setActivePlayer: (
      _: EmbeddedPlayerState,
      action: PayloadAction<EmbeddedPlayerState>
    ) => action.payload,

    resetPlayer: (state: EmbeddedPlayerState) => (state = initialState),
  },

  selectors: { getActivePlayer: (state) => state },
})

export const { setActivePlayer, resetPlayer } = embeddedPlayer.actions
export const { getActivePlayer } = embeddedPlayer.selectors
export default embeddedPlayer.reducer
