import axios from 'axios';
import { Dispatch } from 'redux';


export const fetchMyExtensions = async (token: string | null, dispatch: Dispatch, setExtensions: (extensions: any[]) => void) => {
    try {
        dispatch({ type: 'START_LOADING' });
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/streamer/extensions/getextensions`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        console.log(JSON.stringify(response.data));
        setExtensions(response.data);
        dispatch({ type: 'STOP_LOADING' });
    } catch (error) {
        dispatch({ type: 'STOP_LOADING' });
        console.error('Error fetching my extensions:', error);
    }
};