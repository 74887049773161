import React from 'react';
import { MdNotificationsActive } from 'react-icons/md'; // Importing the chosen icon
import styled from 'styled-components';
import useTestAlerts from './api/TestAlertActions';

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  background-color: #6200ea;
  color: yellow;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #3700b3;
  }

  svg {
    margin-right: 8px;
  }
`;

const AdminTestAlerts: React.FC = () => {
  const { sendFakeTwitchSubscription, sendFakeTwitchBits, sendFakeYouTubeSuperChat, sendFakeTikTokGift, sendFakeYouTubeSponsor, sendFakeTikTokSubscriber, sendFakeTwitchChannelPoints } = useTestAlerts();


  const handleTestTwitchSubscription = async () => {
    const result = await sendFakeTwitchSubscription();
    alert(result.message);
  };

  const handleTestBitsAlert = async () => {
    const result = await sendFakeTwitchBits();
    alert(result.message);
  };

  const handleTestTwitchPoints = async () => {
    const result = await sendFakeTwitchChannelPoints();
    alert(result.message);
  };


  const handleTestYouTubeSuperChat = async () => {
    const result = await sendFakeYouTubeSuperChat();
    alert(result.message);
  };

  const handleTestTikTokGift = async () => {
    const result = await sendFakeTikTokGift();
    alert(result.message);
  };

  const handleTestYouTubeSponsor = async () => {
    const result = await sendFakeYouTubeSponsor();
    alert(result.message);
  };

  const handleTestTiktokSubscription = async () => {
    const result = await sendFakeTikTokSubscriber();
    alert(result.message);
  };

  return (
    <div>
      <h3>Admin Test Alerts</h3>
      <StyledButton onClick={handleTestTwitchSubscription}>
        <MdNotificationsActive size={24} />
        Test Twitch Subscription on Cowsep
      </StyledButton>
      <StyledButton onClick={handleTestBitsAlert}>
        <MdNotificationsActive size={24} />
        Test Twitch Bits on Cowsep
      </StyledButton>
      <StyledButton onClick={handleTestTwitchPoints}>
        <MdNotificationsActive size={24} />
        Test Twitch Channel Points on Cowsep
      </StyledButton>
      <StyledButton onClick={handleTestYouTubeSuperChat}>
        <MdNotificationsActive size={24} />
        Test YouTube Super Chat
      </StyledButton>
      <StyledButton onClick={handleTestTikTokGift}>
        <MdNotificationsActive size={24} />
        Test TikTok Gift
      </StyledButton>
      <StyledButton onClick={handleTestYouTubeSponsor}>
        <MdNotificationsActive size={24} />
        Test YouTube Sponsor
      </StyledButton>
      <StyledButton onClick={handleTestTiktokSubscription}>
        <MdNotificationsActive size={24} />
        Test Tiktok Subscription
      </StyledButton>
    </div>
  );
};

export default AdminTestAlerts;
