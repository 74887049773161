import {
  UIEvent as ReactUIEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import ChatMessage from "../chatmessage/ChatMessageModel"

interface ChatScrollOptions {
  max?: number
  onChange?: (messages: ChatMessage[]) => void
}

const isScrolledToBottom = (element: HTMLDivElement) =>
  element.scrollHeight - element.clientHeight <= element.scrollTop + 5

const useChatScroll = (
  messages: ChatMessage[],
  { max = 150, onChange }: ChatScrollOptions
) => {
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true)
  const [pausedMessageCount, setPausedMessageCount] = useState(0)

  const scrollElementRef = useRef<HTMLDivElement>(null)

  const handleScroll = (event: ReactUIEvent<HTMLDivElement, UIEvent>) => {
    const isAtBottom = isScrolledToBottom(event.currentTarget)
    setShouldScrollToBottom(isAtBottom)

    if (isAtBottom) {
      setPausedMessageCount(0) // Reset paused message count when scrolled to bottom manually
    }
  }

  const scrollToBottom = useCallback(
    (opts?: { force?: boolean }) => {
      if (!scrollElementRef.current?.lastElementChild) return

      if (shouldScrollToBottom || opts?.force) {
        scrollElementRef.current.lastElementChild.scrollIntoView({
          // behavior: "smooth",
          block: "end",
          inline: "nearest",
        })

        setPausedMessageCount(0)
      }
    },
    [shouldScrollToBottom]
  )

  const updatePausedMessageCount = useCallback(() => {
    if (!scrollElementRef.current) return

    if (!isScrolledToBottom(scrollElementRef.current)) {
      setPausedMessageCount((count) => Math.min(count + 1, max))
    }
  }, [max])

  useEffect(() => {
    updatePausedMessageCount()
  }, [messages])

  useEffect(() => {
    // onChange(messages)
    scrollToBottom()
  }, [messages, scrollToBottom])

  return {
    handleScroll,
    scrollToBottom,
    scrollElementRef,
    showScrollToBottomButton: !shouldScrollToBottom,
    pausedMessageCount,
  }
}

export default useChatScroll
