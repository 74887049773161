import { useDispatch } from "react-redux"
import { setUserStreamerProfile } from "../../../../store/reducers/userStreamerProfileReducer"
import { useToken } from "../../../contexts/TokenContext"
import { useUserProfile } from "../../../contexts/UserProfileContext"
import ActionButton from "../../../ui/ActionButton"

function BecomeStreamerButton() {
  const dispatch = useDispatch()

  const { token } = useToken()
  const { profile, setProfile } = useUserProfile()

  const isTwitchLinked = !!profile?.linkedAccounts?.Twitch

  const handleBecomeStreamer = async () => {
    try {
      if (!token) return

      dispatch({ type: "START_LOADING" })
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/streamer/join`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )

      if (!response.ok) {
        throw new Error("Failed to become a streamer")
      }

      const data = await response.json()
      console.log(data)

      setProfile(data.existingUser)
      dispatch(setUserStreamerProfile(data.newStreamer))

      console.log("Successfully became a streamer")
    } catch (error) {
      console.error("Error:", error)
    } finally {
      dispatch({ type: "STOP_LOADING" })
    }
  }

  return (
    <ActionButton
      onClick={handleBecomeStreamer}
      disabled={{
        status: !isTwitchLinked,
        content: isTwitchLinked
          ? ""
          : "Currently, only accounts with a Twitch account linked are supported.",
      }}
      style={{ width: "100%" }}
    >
      Become a Streamer ({">"}2000 followers)
    </ActionButton>
  )
}

export default BecomeStreamerButton
