// src/components/FollowButtonBox.js
import React from 'react';
import { useToken } from '../../../../contexts/TokenContext';
import { useUserProfile } from '../../../../contexts/UserProfileContext';
import FollowButton from '../../../../button/viewersupport/follow/FollowButton';
import './FollowButtonBox.css'; // New CSS file for styling
import DonateButton from '../../../../button/viewersupport/donate/DonateButton';
import { useStreamerProfile } from '../../../../contexts/StreamerProfileContext';

const FollowButtonBox = () => {
    const { token } = useToken();
    const { profile } = useUserProfile();
    const { streamerProfile } = useStreamerProfile();    
  
    const donateUrl = streamerProfile?.settings?.donateButton;

    return (
        <div className="followButtonBox">
            {token && profile && <FollowButton /> }
            {donateUrl && <DonateButton url={donateUrl} />}
            {/*
            <SubscribeButton /> */}
        </div>
    );
};

export default FollowButtonBox;
