import { useCallback } from 'react';
import { useUserProfile } from '../../contexts/UserProfileContext';
import { useWebSocket } from '../../contexts/WebSocketContext';
import { useStreamerProfile } from '../../contexts/StreamerProfileContext';
import { useAuthModule } from '../../auth/login/UseAuthModule'; // Add this import
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

export const useSendMessage = () => {
    const { profile } = useUserProfile();
    const { socket } = useWebSocket();
    const { streamerProfile } = useStreamerProfile();
    const { handleLoginButtonClick } = useAuthModule(); // Use the hook
    const currentPlatform = useSelector((state: RootState) => state.currentChatPlatform.currentPlatform);
    const message = useSelector((state: RootState) => state.chatInput.chatMessage);

    const sendMessage = useCallback(() => {
        if (!profile) {
            handleLoginButtonClick();
            return false;
        }

        if (socket && socket.readyState === WebSocket.OPEN) {
            const messageData = {
                type: 'chat',
                chatRPGChannelId: streamerProfile?.chatrpg_id,
                twitchChannelId: streamerProfile?.twitchId,
                twitchChannelUsername: streamerProfile?.twitch_username,
                platform: currentPlatform,
                message,
            };

            socket.send(JSON.stringify(messageData));
            console.log("You just sent a message!");
            return true;
        } else {
            console.log('WebSocket is not connected.');
            return false;
        }
    }, [profile, socket, streamerProfile, currentPlatform, handleLoginButtonClick, message]);

    
    return sendMessage;
};
