import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { useStreamerProfile } from './StreamerProfileContext';
import { useUserProfile } from './UserProfileContext';
import { useToken } from './TokenContext';
import axios from 'axios';
import { logWithBlueBackground, logWithYellowBackground } from '../../utils/logging';

export type UserOnStreamerSettings = {
    displayTwitchBadgesWhenChatRPG: boolean;
    // Add more settings as needed
};

export type UserOnStreamer = {
    chatrpg_id: string;
    chatrpg_username: string;
    streamer_chatrpg_id: string;
    ban_status: boolean;
    moderator_status: boolean;
    broadcaster_status: boolean;
    badges: Array<{
        badgeType: string;
        badgeValue: string;
    }>;
    settings: UserOnStreamerSettings;
};

type UserOnStreamerContextType = {
    userOnStreamer: UserOnStreamer | null;
    setUserOnStreamer: React.Dispatch<React.SetStateAction<UserOnStreamer | null>>;
};

const UserOnStreamerContext = createContext<UserOnStreamerContextType | undefined>(undefined);

type UserOnStreamerProviderProps = {
    children: ReactNode;
};

export const UserOnStreamerProvider: React.FC<UserOnStreamerProviderProps> = ({ children }) => {
    const [userOnStreamer, setUserOnStreamer] = useState<UserOnStreamer | null>(null);
    const { profile } = useUserProfile();
    const { streamerProfile } = useStreamerProfile();
    const { token } = useToken();

    useEffect(() => {
        const fetchUserOnStreamer = async () => {
            if (profile && streamerProfile && token) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/chatrpg/useronstreamer/${streamerProfile.chatrpg_id}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`, // Use Bearer token for authorization
                        },
                    });

                    // logWithBlueBackground("This is our User On Streamer " + JSON.stringify(response.data));
                    setUserOnStreamer(response.data); // Set the userOnStreamer state
                } catch (error) {
                    console.error("Error fetching user on streamer info:", error);
                }
            }
            else {
                setUserOnStreamer(null);
            }
        };

        fetchUserOnStreamer();
    }, [streamerProfile?.chatrpg_id, profile?.chatrpg_id]); // Dependency array includes profile, streamerProfile, and token

    useEffect(() => {
        console.log('UserOnStreamer updated:', userOnStreamer);
    }, [userOnStreamer]);

    return (
        <UserOnStreamerContext.Provider value={{ userOnStreamer, setUserOnStreamer }}>
            {children}
        </UserOnStreamerContext.Provider>
    );
};

export const useUserOnStreamer = () => {
    const context = useContext(UserOnStreamerContext);
    if (context === undefined) {
        throw new Error('useUserOnStreamer must be used within a UserOnStreamerProvider');
    }
    return context;
};
