// src/contexts/GlobalBadgeContext.tsx
import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { GlobalBadge } from '../../types/GlobalBadge'; // Import your GlobalBadge type
import { fetchChatRPGBadges, fetchKickBadges, fetchTwitchBadges, fetchYoutubeBadges } from '../../helpers/badgeHelper';
import { ChatRPGBadge } from '../../types/ChatRPGBadge';
import { KickBadge } from '../../types/KickBadge';
import { YoutubeBadge } from '../../types/YoutubeBadge';

type GlobalBadgeContextType = {
    globalBadges: GlobalBadge[];
    chatRpgBadges: ChatRPGBadge[];
    kickBadges: KickBadge[];
    youtubeBadges: YoutubeBadge[];
    setYoutubeBadges: React.Dispatch<React.SetStateAction<YoutubeBadge[]>>;
    setGlobalBadges: React.Dispatch<React.SetStateAction<GlobalBadge[]>>;
    setChatRpgBadges: React.Dispatch<React.SetStateAction<ChatRPGBadge[]>>;
    setKickBadges: React.Dispatch<React.SetStateAction<KickBadge[]>>;
};

const GlobalBadgeContext = createContext<GlobalBadgeContextType | undefined>(undefined);

type GlobalBadgeProviderProps = {
    children: ReactNode;
};
export const GlobalBadgeProvider: React.FC<GlobalBadgeProviderProps> = ({ children }) => {
    const [globalBadges, setGlobalBadges] = useState<GlobalBadge[]>([]);
    const [chatRpgBadges, setChatRpgBadges] = useState<ChatRPGBadge[]>([]);
    const [kickBadges, setKickBadges] = useState<KickBadge[]>([]);
    const [youtubeBadges, setYoutubeBadges] = useState<YoutubeBadge[]>([]);

    useEffect(() => {
        fetchTwitchBadges(setGlobalBadges); // Pass the setGlobalBadges function to fetchTwitchBadges
        fetchChatRPGBadges(setChatRpgBadges); // Fetch ChatRPG badges
        fetchKickBadges(setKickBadges); // Fetch ChatRPG badges
        console.log("FETCHING YOUTUBE BADGES NOW!");
        fetchYoutubeBadges(setYoutubeBadges);
    }, []);

    return (
        <GlobalBadgeContext.Provider value={{ globalBadges, setGlobalBadges, chatRpgBadges, setChatRpgBadges, kickBadges, setKickBadges, youtubeBadges, setYoutubeBadges }}>
            {children}
        </GlobalBadgeContext.Provider>
    );
};

export const useGlobalBadges = () => {
    const context = useContext(GlobalBadgeContext);
    if (context === undefined) {
        throw new Error('useGlobalBadges must be used within a GlobalBadgeProvider');
    }
    return context;
};
