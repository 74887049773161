import { Button, Flex, Text } from "@radix-ui/themes"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import {
  getActivePlayer,
  setActivePlayer,
} from "../../../../store/slices/EmbeddedPlayer"
import PLATFORMS from "../../../config/platformConfig"
import { useStreamerProfile } from "../../../contexts/StreamerProfileContext"

function EmbedPlatformSelector() {
  const dispatch = useDispatch()

  const { streamerProfile, players } = useStreamerProfile()

  const { index: activePlayerIndex, platform: activePlayerPlatform } =
    useSelector(getActivePlayer)

  if (!streamerProfile || players.length === 0) {
    return null
  }

  return (
    <Wrapper justify="center" align="center" gap="2">
      {players.map((player, index) => {
        const platformDetails = PLATFORMS[player.platform]
        const isSelected =
          index === activePlayerIndex &&
          player.platform === activePlayerPlatform

        if (!platformDetails.embeddable) return null

        return (
          <Button
            key={player.url}
            type="button"
            variant="surface"
            color={isSelected ? "yellow" : "gray"}
            style={{
              border: `1px var(${
                isSelected ? "--chatrpg-logo-color" : "--gray-5"
              }) solid`,
            }}
            onClick={() => {
              dispatch(
                setActivePlayer({
                  index,
                  platform: player.platform,
                  url: player.url,
                })
              )
            }}
          >
            <Badge
              src={platformDetails.badge}
              alt={player.platform}
              title={player.platform}
            />

            <Text weight="bold" style={{ color: "white" }}>
              {player.username}
            </Text>
          </Button>
        )
      })}
    </Wrapper>
  )
}

const Wrapper = styled(Flex)`
  font-family: var(--default-font-family);
  color: white;
`

const Badge = styled.img`
  max-height: 100%;
  width: auto;
  min-width: 20px;
  min-height: 20px;
  padding: 0.375rem 0;
`

export default EmbedPlatformSelector
