import { PlatformKey } from "../components/config/platformConfig"

export interface Streamer {
  chatrpg_id: string
  chatrpg_username: string
  chatrpg_profilePicture: string
  chatrpg_partner: boolean
}

export interface StreamerStatus {
  chatrpg_id: string
  twitch_username?: string
  twitch_id?: string
  youtube_channel_id?: string
  platforms: StreamerPlatformStatus[]
}

export interface StreamerPlatformStatus {
  platform: PlatformKey
  viewerCount?: number
  videoId: string
}

export async function getFollowedStreamers(token: string | null) {
  if (!token) throw new Error("Unauthorized.")

  const response = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/profile/sidebar`,
    { method: "POST", headers: { Authorization: `Bearer ${token}` } }
  )

  const data: { streamers: Streamer[] } = await response.json()

  return data.streamers
}

export async function getFollowedStreamersStatus(token: string | null) {
  if (!token) throw new Error("Unauthorized.")

  const response = await fetch(
    `${process.env.REACT_APP_WEBSOCKET_SERVER_URL}/polling/userlivefollowedstreamers`,
    { method: "POST", headers: { Authorization: `Bearer ${token}` } }
  )

  const data: { onlineStreamers: StreamerStatus[] } = await response.json()

  return data.onlineStreamers
}

export async function updateFollowedStreamersOrder(
  token: string | null,
  orderedStreamerIds: string[]
) {
  if (!token) throw new Error("Unauthorized.")

  const response = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/profile/savesidebar`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ chatrpg_ids: orderedStreamerIds }),
    }
  )

  console.log(await response.text())
}
