import MiniSearch, {
  Query,
  SearchOptions,
  SearchResult as MiniSearchResult,
} from "minisearch"
import {
  createContext,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import replace from "react-string-replace"
import { getSevenTvEmotes } from "../api/emotes/SevenTV"
import { getTwitchGlobalEmotes } from "../api/emotes/Twitch"
import { useStreamerProfile } from "../components/contexts/StreamerProfileContext"

type EmoteMap<T> = Record<string, T>

interface SearchIndexable {
  id: string
  name: string
}

export interface EmoteMeta extends SearchIndexable {
  url: string
  source: string
}

interface EmoteMetadata {
  twitch: EmoteMeta[]
  sevenTv: EmoteMeta[]
  search: (query: Query, options?: SearchOptions) => SearchResult<EmoteMeta>[]
  parse: (
    text: string,
    options?: { onEmoteClick?: (name: string) => void }
  ) => string | ReactNode[]
  convertToMap: <T>(data: EmoteMeta[]) => EmoteMap<T>
}

type SearchResult<T> = MiniSearchResult & T
export type EmoteSearchResult = SearchResult<EmoteMeta>

const EmoteContext = createContext<EmoteMetadata | null>(null)

export function useEmotes() {
  const context = useContext(EmoteContext)

  if (!context) {
    throw new Error("useEmotes must be used within an EmoteProvider.")
  }

  return context
}

// TODO: Refactor
const SearchEngine = new MiniSearch<SearchIndexable>({
  fields: ["name"],
  storeFields: ["name", "url", "source"],
})

function EmoteProvider({ children }: PropsWithChildren) {
  const { status: streamerPageStatus, streamerProfile } = useStreamerProfile()

  const [emotes, setEmotes] = useState<
    Pick<EmoteMetadata, "twitch" | "sevenTv">
  >({ twitch: [], sevenTv: [] })

  // TODO: See if Redux `createApi` can handle this
  useEffect(() => {
    if (streamerPageStatus !== "idle") return

    console.log("fetching emotes...")
    getTwitchGlobalEmotes()
      .then((data) => {
        console.log("adding twitch emotes...")

        setEmotes((prev) => ({ ...prev, twitch: data }))
        data.forEach(addEmoteToIndex)
      })
      .catch((error) => {
        console.error(error)
        setEmotes((prev) => ({ ...prev, twitch: [] }))
      })

    if (streamerProfile.twitchId) {
      getSevenTvEmotes(streamerProfile.twitchId)
        .then(async (data) => {
          console.log("adding 7tv emotes...")

          setEmotes((prev) => ({ ...prev, sevenTv: data }))
          data.forEach(addEmoteToIndex)
        })
        .catch((error) => {
          console.error(error)
          setEmotes((prev) => ({ ...prev, sevenTv: [] }))
        })
    }

    return () => {
      console.log("resetting emote index...")
      SearchEngine.removeAll()
    }
  }, [streamerPageStatus, streamerProfile])

  const addEmoteToIndex = (data: EmoteMeta) => {
    if (!SearchEngine.has(data.id)) SearchEngine.add(data)
  }

  const convertToMap = useCallback<EmoteMetadata["convertToMap"]>(
    (data) =>
      data.reduce((acc, emote) => {
        acc[emote.id] = emote
        return acc
      }, {} as EmoteMap<any>),
    []
  )

  const search = useCallback<EmoteMetadata["search"]>(
    (query: Query, options?: SearchOptions) =>
      SearchEngine.search(query, {
        fuzzy: 0.2,
        prefix: true,
        ...options,
      }) as SearchResult<EmoteMeta>[],
    []
  )

  const parse = useCallback<EmoteMetadata["parse"]>(
    (text: string, options): string | React.ReactNode[] => {
      let message: string | ReactNode[] = text

      SearchEngine.search(text, { fuzzy: false, prefix: false }).forEach(
        ({ name, url }) => {
          const escapedName = name.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")

          message = replace(
            message,
            new RegExp(`(?<=^|\\s+)(${escapedName})(?=$|\\s+)`),
            (_, i) => (
              <div>
                <img
                  key={`${name}${i}`}
                  src={url}
                  alt={name}
                  title={name}
                  onClick={() => options?.onEmoteClick?.(name)}
                />
              </div>
            )
          )
        }
      )

      return message
    },
    []
  )

  const value = useMemo<EmoteMetadata>(
    () => ({ ...emotes, convertToMap, search, parse }),
    [convertToMap, emotes, parse, search]
  )

  return <EmoteContext.Provider value={value}>{children}</EmoteContext.Provider>
}

export default EmoteProvider
