import { Button, ScrollArea } from "@radix-ui/themes"
import { FaPause } from "react-icons/fa"
import { styled } from "styled-components"
import { useMobileContext } from "../../contexts/MobileContext"
import ChatLine from "../chatmessage/ChatLine"
import ChatNotificationBox from "../ChatNotificationBox"
import useChatScroll from "./ChatScrollLogic"
import useChatWebSocket from "./ChatWebSocketDisplay"

function ChatDisplay() {
  const { isMobile } = useMobileContext()
  const { messages } = useChatWebSocket()

  // const virtualizer = useVirtualizer({
  //   count: messages.length,
  //   getScrollElement: () => scrollElementRef.current,
  //   getItemKey: (index) => messages[index].key,
  //   estimateSize: () => 45,
  //   initialOffset: 0,
  //   overscan: 5,
  // })

  // const items = virtualizer.getVirtualItems()

  const {
    handleScroll,
    scrollToBottom,
    scrollElementRef,
    showScrollToBottomButton,
    pausedMessageCount,
  } = useChatScroll(messages, {
    // onChange: (m) => {
    //   if (m.length > 0 && !showScrollToBottomButton) {
    //     virtualizer.scrollToIndex(m.length - 1)
    //   }
    // },
  })

  return (
    <ScrollWrapper
      scrollbars="vertical"
      // type="always"
      $mobile={isMobile}
      onScroll={handleScroll}
      ref={scrollElementRef}
    >
      {/* <VirtualizedWrapper style={{ height: virtualizer.getTotalSize() }}> */}
      {/* <VirtualizedDynamicContainer
          style={{ transform: `translateY(${items[0]?.start ?? 0}px)` }}
        > */}
      {messages.map((message) => {
        //message holds type + data - and data holds data
        switch (message.type) {
          case "TwitchEvent":
          case "YoutubeEvent":
          case "TiktokEvent":
          case "StreamlabsEvent":
          case "StreamelementsEvent":
          case "KickEvent":
          case "CustomEvent":
            console.log("This is our event: " + JSON.stringify(message))
            //I'm annoyed because the .message.type is TwitchEvent - and then message.data.type is also TwitchEvent - some client side fuckery has to be going on!! Has to be in ChatMessageModel.tsx
            // This is our Youtube event:
            // {
            //   "_id": "lxyyp7fr-oyglti",
            //   "type": "YoutubeEvent",
            //   "source": "user",
            //   "data": {
            //     "type": "youtubeSubscription",
            //     "data": {
            //       "userName": "Merry Jane",
            //       "isAnonymous": false,
            //       "message": "Chained Together with JB? 😁",
            //       "context": "superChat",
            //       "amount": "$2.00",
            //       "currency": "USD"
            //     }
            //   },
            //   "options": { "timestamp": "2024-06-28T17:23:16.071Z" }
            // }

            return (
              <ChatNotificationBox
                key={message.key}
                message={message}
              // index={index}
              // ref={virtualizer.measureElement}
              />
            )

          case "None":
            return null

          default:
            return (
              <ChatLine
                key={message.key}
                message={message}
              // index={index}
              // ref={virtualizer.measureElement}
              />
            )
        }
      })}
      {/* </VirtualizedDynamicContainer> */}
      {/* </VirtualizedWrapper> */}

      {showScrollToBottomButton && (
        <ScrollToBottom>
          <Button
            size="3"
            color="gray"
            variant="surface"
            // onClick={() => virtualizer.scrollToIndex(messages.length - 1)}
            onClick={() => scrollToBottom({ force: true })}
          >
            <FaPause size="15" />

            {pausedMessageCount > 0
              ? `${pausedMessageCount > 99 ? "99+" : pausedMessageCount
              } new message${messages.length > 0 ? "s" : ""}`
              : "Chat Paused"}
          </Button>
        </ScrollToBottom>
      )}
    </ScrollWrapper>
  )
}

const ScrollWrapper = styled(ScrollArea) <{ $mobile: boolean }>`
  flex-grow: 1;
  padding-top: ${({ $mobile }) => ($mobile ? "0" : "0.5rem")};

  .rt-ScrollAreaScrollbar {
    margin: 0;
    width: 0.375rem;
  }
`

// const VirtualizedWrapper = styled.div`
//   position: relative;
//   width: 100%;
// `

// const VirtualizedDynamicContainer = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
// `

const ScrollToBottom = styled.div`
  position: absolute;
  bottom: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  min-width: max-content;

  button {
    background-color: #212121;
    color: white;
  }
`

export default ChatDisplay
