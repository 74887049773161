import { BrowserRouter as Router } from "react-router-dom"
import Content from "./components/Content"
import { MobileContextProvider } from "./components/contexts/MobileContext"
import { OrientationProvider } from "./components/contexts/OrientationContext"
import { TokenContextProvider } from "./components/contexts/TokenContext"
import UserProfileProvider from "./components/contexts/UserProfileContext"

import "@radix-ui/themes/styles.css"
import "./App.css"

function App() {
  return (
    <Router>
      <TokenContextProvider>
        <UserProfileProvider>
          <MobileContextProvider>
            <OrientationProvider>
              <Content />
            </OrientationProvider>
          </MobileContextProvider>
        </UserProfileProvider>
      </TokenContextProvider>
    </Router>
  )
}

export default App
