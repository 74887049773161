import axios from "axios"

const BASE_URL = process.env.REACT_APP_SERVER_URL

export async function linkAccount(
  platform: string,
  platformUsername: string,
  token: string | null
) {
  if (!token) throw new Error("Unauthorized.")

  const url = `${BASE_URL}/streamer/${platform.toLowerCase()}/connect`

  const response = await axios.post(
    url,
    { platformUsername },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  )

  console.log("Link response:", response.data)

  return response.data
}
