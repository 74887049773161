import { AnyAction, Reducer } from "redux"
import { Extension } from "../../components/contexts/StreamerProfileContext"

export type StreamerSettings = {
  donateButton?: string
  placeholder?: string
}

export type EmbeddedPlayer = {
  platform: string
  username: string
}

export type AboutMePlatformIcons = {
  platform: string
  username: string
}

export type UserStreamerProfile = {
  chatrpg_username: string
  chatrpg_id: string
  followers: number
  twitchId?: string
  user_channelInfo?: string
  user_chatrpg_profilePicture?: string
  user_twitch_username?: string
  embeddedPlayers?: EmbeddedPlayer[]
  aboutMePlatformIcons: AboutMePlatformIcons[]
  settings?: StreamerSettings
  extensions?: Extension[]
  elevatedTwitch?: boolean

  twitch_username?: string
  youtubeChannelId?: string
  tiktok_username?: string
  kick_username?: string
}

export const SET_USER_STREAMER_PROFILE = "SET_USER_STREAMER_PROFILE"
export const CLEAR_USER_STREAMER_PROFILE = "CLEAR_USER_STREAMER_PROFILE"
export const UPDATE_USER_STREAMER_PROFILE_FIELD =
  "UPDATE_USER_STREAMER_PROFILE_FIELD"

export const setUserStreamerProfile = (profile: UserStreamerProfile) => ({
  type: SET_USER_STREAMER_PROFILE,
  payload: profile,
})

export const clearUserStreamerProfile = () => ({
  type: CLEAR_USER_STREAMER_PROFILE,
})

export const updateUserStreamerProfileField = (
  payload: Partial<UserStreamerProfile>
) => ({ type: UPDATE_USER_STREAMER_PROFILE_FIELD, payload })

export const userStreamerProfileReducer: Reducer<
  UserStreamerProfile | null,
  AnyAction
> = (state: UserStreamerProfile | null = null, action) => {
  switch (action.type) {
    case SET_USER_STREAMER_PROFILE:
      return action.payload

    case UPDATE_USER_STREAMER_PROFILE_FIELD:
      return state ? { ...state, ...action.payload } : null

    case CLEAR_USER_STREAMER_PROFILE:
      return null

    default:
      return state
  }
}

export default userStreamerProfileReducer
