import { Card, Flex, Heading } from "@radix-ui/themes"
import { useUserProfile } from "../../../../../contexts/UserProfileContext"
import BecomeDeveloperButton from "../../../../buttons/streamer/BecomeDeveloperButton"
import BecomeStreamerButton from "../../../../buttons/streamer/BecomeStreamerButton"

function AccountUpgradesSection() {
  const { profile } = useUserProfile()

  if (!profile) return null

  return (
    <Card size="3" style={{ height: "min-content" }}>
      <Heading as="h2" size="3" weight="medium" mb="4" trim="start">
        Upgrade your account now!
      </Heading>

      <Flex
        direction="column"
        align="center"
        gap="3"
        minWidth={{ initial: "18rem", md: "20rem" }}
      >
        {!profile.isStreamer && <BecomeStreamerButton />}
        {!profile.isDeveloper && <BecomeDeveloperButton />}
      </Flex>
    </Card>
  )
}

export default AccountUpgradesSection
