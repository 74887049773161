import { PlatformKey } from "../../../config/platformConfig"
import { useStreamerProfile } from "../../../contexts/StreamerProfileContext"

interface EmbeddedPlayerProps {
  platform: PlatformKey
  url: string
}

function EmbeddedPlayer({ platform, url }: EmbeddedPlayerProps) {
  const { streamerProfile } = useStreamerProfile()

  return (
    <iframe
      src={url}
      title={`${platform} Stream Embed - ${streamerProfile?.chatrpg_username}`}
      width="100%"
      height="100%"
      scrolling="no"
      allowFullScreen
    />
  )
}

export default EmbeddedPlayer
