import { useDispatch } from "react-redux"
import styled, { keyframes } from "styled-components"
import { EmoteSearchResult } from "../../providers/EmoteProvider"
import { setChatMessage } from "../../store/reducers/inputChatReducer"
import { PopoverContent } from "../ui/Popover.styled"

interface EmotePopoverProps {
  emotes: EmoteSearchResult[]
  message: string
  onClose: () => void
}

function EmotePopover({ emotes, message, onClose }: EmotePopoverProps) {
  const dispatch = useDispatch()

  const disableAutoFocus = (e: Event) => e.preventDefault()

  if (!emotes.length) return null

  return (
    <Content
      sideOffset={10}
      noArrow
      onOpenAutoFocus={disableAutoFocus}
      onCloseAutoFocus={disableAutoFocus}
    >
      {emotes.map(({ id, name, url, source, ...rest }) => (
        <EmoteButton
          key={id}
          onClick={() => {
            const replaced = message.replace(/:?\b\w+\b$/i, name)
            console.log({ chatMessage: message, replaced }, rest)
            dispatch(setChatMessage(`${replaced} `))
            onClose()
          }}
        >
          <img
            src={url}
            alt={name}
            title={name}
            width={96}
            height={32}
            style={{ width: "auto", height: "auto" }}
          />
          <span>{name}</span>
          <span>({source})</span>
        </EmoteButton>
      ))}
    </Content>
  )
}

const scaleIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(2rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const Content = styled(PopoverContent)`
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  min-width: 24.125rem;
  max-height: 70vh;
  overflow-y: scroll;
  padding: 0.65rem;
  border-radius: 0.5rem;

  font-size: 0.875rem;
  background-color: var(--sidebar-color);
  border: 1px solid #999999;

  transform-origin: var(--radix-popover-content-transform-origin);
  animation: ${scaleIn} 125ms;
`

const EmoteButton = styled.button`
  display: flex;
  align-items: center;
  line-height: 1;
  gap: 0.375rem;
  padding: 0.5rem;
  border-radius: 0.25rem;

  &:hover {
    background-color: #a8b0cc28;
  }

  span {
    color: white;
  }

  span:nth-child(3) {
    margin-left: -0.125rem;
    color: #39a0ed;
  }
`

export default EmotePopover
