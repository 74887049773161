import axios from "axios"

const BASE_URL = process.env.REACT_APP_SERVER_URL

export async function unlinkStreamerAccount(
  platform: string,
  token: string | null
) {
  if (!token) throw new Error("Unauthorized.")

  const url = `${BASE_URL}/streamer/${platform.toLowerCase()}/disconnect`

  try {
    const response = await axios.post(
      url,
      { platformToRemove: platform },
      { headers: { Authorization: `Bearer ${token}` } }
    )

    console.log("Unlink streamer response:", response.data)

    return response.data
  } catch (error: any) {
    console.error(
      "Error during unlinking streamer:",
      error.response ? error.response.data : error.message
    )
    throw error
  }
}

export async function unlinkAccount(platform: string, token: string | null) {
  if (!token) throw new Error("Unauthorized.")

  const url = `${BASE_URL}/profile/unlinkaccount`

  try {
    const response = await axios.post(
      url,
      { platformToRemove: platform },
      { headers: { Authorization: `Bearer ${token}` } }
    )

    console.log("Unlink response:", response.data)

    return response.data
  } catch (error: any) {
    console.error(
      "Error during unlinking normally:",
      error.response ? error.response.data : error.message
    )
    throw error
  }
}
